<template>
    <div class="template-page privacy-policy">
        <basic-page :pagedata="this.pageData" :bannerimg="this.bannerimg">
            <div v-if="pageData.body!=undefined" v-html="this.pageData.body[0].processed"></div>
        </basic-page>
    </div>
</template>

<script>
import { fetchSingleNode } from '../libs/drupalClient'
import BasicPage from './BasicPage.vue'

export default {
  components: { BasicPage },
    name: 'privacy-policy',
    data: () => {
        return {
            currentPageId: "a77e0238-cab3-49f0-a3b1-b08b40510514",
            pageData: {},
            bannerimg: null
        }
    },
    methods: {
    },
    computed: {

    },
    mounted() {
        fetchSingleNode('page', this.currentPageId, { include: ['field_banner_top'] })
            .then(res => {
                this.pageData = res[0].attributes
                if(res[0].field_banner_top) this.bannerimg = process.env.VUE_APP_ENDPOINT + res[0].field_banner_top.attributes.uri.url
            })
    }
}
</script>

<style lang="scss">
@import "../styles/colors.scss";
.privacy-policy{
    h2 {
  margin-top: 30px;
}
}
.privacy-policy a {
    color: $secondaryColor;
    font-weight: 700;
    &:hover {
        color: $mainColor;
    }
}
</style>